.heading {
  display: inline;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.4;

  @media (min-width: 35em) {
    font-size: 1.5rem;
  }

  @media (min-width: 40em) {
    font-size: 1.75rem;
  }
}

.heading,
.underline {
  --underline-color: rgb(var(--color-primary) / 24%);
  background-image:
    linear-gradient(
      transparent 0,
      transparent 64%,
      var(--underline-color) 64%,
      var(--underline-color) 88%,
      transparent 88%,
      transparent 100%
    );
  background-repeat: no-repeat;
  background-size: 100%;
}
