.wrike {
  --bg-color: 8 207 101;
  background-color: rgb(var(--bg-color));
  box-shadow: .5rem .5rem rgb(var(--bg-color) / 64%);
  animation: bounceIn .75s both;
}

.wrike__video {
  display: block;
  width: 17rem;
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215,.61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}
