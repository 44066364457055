.error {
  display: grid;
  place-items: center;
  height: 100vh;
  padding: 1.5rem;
}

.error__container {
  text-align: center;
}

.error__heading {
  display: block;
  margin: 0 0 1rem;
}
